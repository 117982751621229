import { Button } from 'antd';
import { EyeIcon, FileDown } from 'lucide-react';
import { EditFilled } from '@assets/svgs';

export const DocumentsAction = ({ document, formik, setOpen, handleDownload }) => {
  const handleClick = async () => {
    setOpen(true);

    if (document?.isUserUpdatedData) {
      await formik.setFieldValue('documentTrainingTableData', document.userModifiedData);
    } else {
      await formik.setFieldValue('documentTrainingTableData', document.documentData);
    }

    await formik.setFieldValue('totalCost', document.totalCost);
    await formik.setFieldValue('totalAmount', document.totalAmount);
    await formik.setFieldValue('markupValue', document.markup);

    await formik.setFieldValue('file', {
      id: document?.id,
      document: document.document,
      vendor: document.vendor.name,
      name: document.name,
    });
  };

  return (
    <div className='flex items-center gap-3 pl-4'>
      <Button
        onClick={() => handleDownload(document?.id, document?.name?.split('.')?.[0])}
        className={'h-min border-none border-primary !p-0 px-4 py-2.5 font-medium text-primary'}
      >
        <FileDown color='#1565D8' className='h-5 w-5' />
      </Button>
      <Button
        onClick={() => {
          handleClick();
          formik.setFieldValue('isEditMode', true);
        }}
        className={'h-min border-none border-primary !p-0 px-4 py-2.5 font-medium text-primary'}
      >
        <EditFilled color='#1565D8' className='h-5 w-5' />
      </Button>
      <Button
        onClick={() => {
          handleClick();
          formik.setFieldValue('isEditMode', false);
        }}
        className={'h-min border-none border-primary !p-0 px-4 py-2.5 font-medium text-primary'}
      >
        <EyeIcon color='#1565D8' className='h-5 w-5' />
      </Button>
    </div>
  );
};
