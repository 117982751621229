import { useState } from 'react';
import { ShowToast } from '@common/components/ShowToast';
import { CreateDocumentValidationSchema } from '@common/validators/VendorsValidationSchema';
import { Button, Input, Table } from 'antd';
import { DocumentsAction } from './components/DocumentsAction';
import GenericHelpers from '../../helpers/genericHelpers';
import { DocumentDrawer, Loader } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, queryKeys, useGetQuery, useUpdateMutation } from '@services';
import { getLocalStorageItem } from '@utils';
import { ACCESS_TOKEN, DEFAULT_ERROR_MESSAGE, ENTER, ERROR, PROCESSED_DOCUMENT_COLUMNS, SUCCESS } from '@constants';

const initialValues = {
  id: null,
  file: null,
  documentTrainingTableData: [],
  isEditMode: false,
  totalCost: null,
  totalAmount: null,
  markupValue: null,
  search: null,
};

const ProcessedDocument = () => {
  const [open, setOpen] = useState(false);
  const [showAllDocuments, setShowAllDocuments] = useState(false);
  const formik = useFormikForm(initialValues, handleSubmit, CreateDocumentValidationSchema);
  const { isEditMode } = formik.values;

  const { data: ProcessedDocuments, isFetching: processedDocumentFetching } = useGetQuery(
    [queryKeys.PROCESSED_DOCUMENTS, showAllDocuments],
    `${apiEndpoints.ProcessedDocuments.LISTING}?all_documents=${showAllDocuments}`
  );

  const { mutate: updateDocument, isPending: updateDocumentPending } = useUpdateMutation(
    apiEndpoints.vendors.CREATE_DOCUMENT,
    () => ShowToast(SUCCESS, 'Document updated successfully.'),
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE),
    { queryKey: [queryKeys.PROCESSED_DOCUMENTS] }
  );

  const handleDownload = async (id, fileName) => {
    try {
      const url = `${import.meta.env.VITE_BACKEND_BASE_URL}${apiEndpoints.ProcessedDocuments.DOWNLOAD(id)}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getLocalStorageItem(ACCESS_TOKEN)}`,
        },
      });
      GenericHelpers.handleDownloadHelper(response, fileName);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const filteredDocuments = formik.values.search
    ? ProcessedDocuments?.data?.results?.filter(document => {
      const customerName = document.customerName || '';
      const deliverToAddress = document.deliverToAddress || '';

      return (
        customerName.toLowerCase().includes(formik.values.search.toLowerCase()) ||
          deliverToAddress.toLowerCase().includes(formik.values.search.toLowerCase())
      );
    }) || []
    : ProcessedDocuments?.data?.results;

  const vendorData =
    filteredDocuments?.map(document => ({
      key: document.id,
      Timestamp: GenericHelpers.formatTimestamp(document.created),
      customerName: document.customerName,
      deliverToAddress: document.deliverToAddress,
      processedBy: GenericHelpers.formatName(document.user?.firstName, document.user?.lastName),
      Vendor: <p className='pl-4'>{document.vendor.name}</p>,
      InputFile: <p className='pl-4'>{document.name}</p>,
      Action: (
        <DocumentsAction
          key={document.id}
          document={document}
          formik={formik}
          setOpen={setOpen}
          handleDownload={handleDownload}
        />
      ),
    })) || [];

  const calculateCosts = value => {
    if (value === '' || value === null) return;

    let totalCost = 0;
    let totalAmount = 0;

    const updatedData = formik.values.documentTrainingTableData?.map(data => {
      const amount = parseFloat((data.amount || '0').toString().replace(/,/g, ''));
      const markupValue = 1 + value / 100;
      const originalCost = amount / markupValue;

      totalCost += data?.item !== 'DC' ? originalCost : 0;
      totalAmount += amount;

      const cost = data?.item !== 'DC' ? parseFloat(originalCost.toFixed(2)) : 0;
      return { ...data, cost };
    });

    formik.setFieldValue('id', `${new Date()}`);
    formik.setFieldValue('documentTrainingTableData', updatedData);
    formik.setFieldValue('totalCost', totalCost.toFixed(2));
    formik.setFieldValue('totalAmount', totalAmount.toFixed(2));
  };

  function handleSubmit() {
    handleDownload(formik.values?.file?.id, formik.values?.file?.name?.split('.')?.[0]);
  }

  return (
    <>
      <div className='flex items-center justify-between px-6'>
        <h3 className='py-8'>Document List</h3>
        <div className='flex items-center gap-2'>
          <Button
            className='h-9'
            disabled={processedDocumentFetching}
            onClick={() => setShowAllDocuments(!showAllDocuments)}
          >
            {showAllDocuments ? 'My Documents' : 'All Documents'}
          </Button>
          <Input
            type='text'
            name='search'
            value={formik.values.search}
            onChange={formik.handleChange}
            placeholder='Search'
            className='ml h-9 w-full max-w-[14.125rem] rounded-md border border-silverBlue text-sm font-medium leading-[1.125rem] focus:outline-none'
          />
        </div>
      </div>
      {processedDocumentFetching ? (
        <div className='flex h-[calc(100vh-180px)] items-center justify-center'>
          <Loader />
        </div>
      ) : (
        <Table
          sticky
          className='h-[calc(100vh-180px)] overflow-y-auto'
          pagination={false}
          columns={PROCESSED_DOCUMENT_COLUMNS}
          dataSource={vendorData}
        />
      )}
      <DocumentDrawer
        title={isEditMode ? 'Edit Document' : 'View Document'}
        open={open}
        onClose={() => {
          setOpen(false);
          formik.clearForm();
        }}
        formik={formik}
        isReadOnly={!isEditMode}
        showReUploadButton={false}
        btnText={'Download'}
        optionalBtnText={isEditMode ? 'update' : 'Edit'}
        showCancelBtn={!!isEditMode}
        optionalBtnHandler={() => {
          if (!isEditMode) {
            formik.setFieldValue('isEditMode', true);
          } else {
            formik.errors.documentTrainingTableData
              ? formik.setFieldError('documentTrainingTableData', formik.errors.documentTrainingTableData)
              : updateDocument({
                data: {
                  documentData: formik.values?.documentTrainingTableData,
                  totalCost: formik.values?.totalCost,
                  totalAmount: formik.values?.totalAmount,
                  markup: formik.values?.markupValue,
                },
                id: formik.values?.file?.id,
              });
          }
        }}
        disable={updateDocumentPending}
        onSearchKeyDown={e => e.key === ENTER && calculateCosts(e.target.value)}
      />
    </>
  );
};

export default ProcessedDocument;
