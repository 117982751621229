import moment from 'moment';

export default class GenericHelpers {
  static convertToSelectOptions = data => {
    return data?.map(item => ({
      value: item?.id?.toString(),
      label: item?.name,
      documentCount: item?.documentCount,
      processing: item?.processing,
      trained: item?.trained,
    }));
  };

  static formatTimestamp = timestamp => {
    return moment(timestamp).format('M/DD/YYYY, hh:mm A');
  };

  static invalidateQueries = (keys, queryClient) => {
    keys.forEach(key => {
      queryClient.invalidateQueries({ queryKey: [key] });
    });
  };

  static async handleDownloadHelper(response, fileName) {
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  static formatName = (firstName, lastName) => {
    if (!firstName && !lastName) return '';
    const capitalize = name => name.charAt(0).toUpperCase() + name.slice(1);
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  };
}
