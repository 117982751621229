import { Input, Label } from '@ui';
import DataHelper from '../../../helpers/dataHelper';

export const SignInForm = ({ formik }) => {
  return (
    <>
      <h1 className='text-4xl font-bold leading-[2.75rem] text-midnightBlue'>Sign In</h1>
      <p className='pt-3 text-base font-normal leading-5 text-steelBlue'>Welcome back! Please enter your details.</p>
      <div className='mt-8 flex flex-col items-start justify-start gap-1.5'>
        {DataHelper.signInFormFields.map((field, index) => (
          <div key={index} className='w-full'>
            <Label htmlFor={field.id} className='text-sm font-normal leading-[1.125rem] text-ashGray'>
              {field.label}
            </Label>
            <Input
              id={field.id}
              name={field.id}
              type={field.type}
              placeholder={field.placeholder}
              className='h-11 rounded-lg border border-silverBlue placeholder:text-dustyBlue'
              value={formik.values[field.id]}
              onChange={formik.handleChange}
            />
            <div className='mt-1 text-xs text-warning'>{formik.errors[field.id]}</div>
          </div>
        ))}
      </div>
    </>
  );
};
