import { ROUTES } from '@routes';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const UNAUTHORIZED_STATUS_CODE = 401;

export const ENABLE = 'enable';
export const ENTER = 'Enter';

export const SUCCESS = 'success';
export const ERROR = 'error';
export const INFO = 'info';
export const WARNING = 'warning';

export const routesTitle = {
  [ROUTES.UPLOAD_NEW]: 'Upload New',
  [ROUTES.DOCUMENT_SETUP]: 'Document Setup',
  [ROUTES.PROCESSED_DOCUMENTS]: 'Processed Documents',
  [ROUTES.VENDORS]: 'Vendors',
};

export const DOCUMENT_SETUP_COLUMNS = [
  {
    title: 'Sample No#',
    dataIndex: 'sampleNo',
    key: 'sampleNo',
  },
  {
    title: 'Input Files',
    dataIndex: 'inputFiles',
    key: 'inputFiles',
  },
  {
    title: 'Action',
    dataIndex: 'Action',
    key: 'Action',
  },
];

export const VENDOR_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
  },
  {
    title: 'Action',
    dataIndex: 'Action',
    key: 'Action',
  },
];

export const PROCESSED_DOCUMENT_COLUMNS = [
  {
    title: 'Timestamp',
    dataIndex: 'Timestamp',
    key: 'Timestamp',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Deliver To Address',
    dataIndex: 'deliverToAddress',
    key: 'deliverToAddress',
  },
  {
    title: 'Vendor',
    dataIndex: 'Vendor',
    key: 'Vendor',
  },
  {
    title: 'Processed By',
    dataIndex: 'processedBy',
    key: 'processedBy',
  },
  {
    title: 'Input File',
    dataIndex: 'InputFile',
    key: 'InputFile',
  },
  {
    title: 'Action',
    dataIndex: 'Action',
    key: 'Action',
  },
];
