import { useCallback } from 'react';
import { ShowErrorMessage } from '@common/components/ShowErrorMessage';
import { Table, Button, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CirclePlus } from 'lucide-react';
import { DeleteIcon } from '@assets/svgs';

export const CustomTable = ({
  formik,
  columns,
  isReadOnly = false,
  showNumbering = true,
  showCounts = false,
  showSectionButton = false,
}) => {
  const generateUniqueKey = useCallback(() => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }, []);

  const handleAdd = () => {
    const newRow = {
      key: generateUniqueKey(),
      ...columns.reduce((acc, col) => ({ ...acc, [col.dataIndex]: '' }), {}),
    };
    formik.setFieldValue('documentTrainingTableData', [...formik.values.documentTrainingTableData, newRow]);
  };

  const handleAddSection = () => {
    const newSection = {
      key: generateUniqueKey(),
      isSection: true,
      content: '',
    };
    formik.setFieldValue('documentTrainingTableData', [...formik.values.documentTrainingTableData, newSection]);
  };

  const handleDelete = key => {
    const newData = formik.values.documentTrainingTableData.filter(item => item.key !== key);
    formik.setFieldValue('documentTrainingTableData', newData);
  };

  const handleBlur = (e, key, dataIndex) => {
    const newData = [...formik.values.documentTrainingTableData];
    const index = newData.findIndex(item => item.key === key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [dataIndex]: e.target.value });
      formik.setFieldValue('documentTrainingTableData', newData);
    }
  };

  const makeValueFixedTo2Decimals = (text, column) => {
    const parseValue = parseFloat((text || '0').toString().replace(/,/g, ''));
    return column?.dataIndex === 'amount' || column?.dataIndex === 'rate' ? parseValue.toFixed(2) : text;
  };

  const enhancedColumns = columns.map(column => ({
    ...column,
    render: (text, record) => {
      if (record.isSection && column.dataIndex === 'description') {
        return {
          children: isReadOnly ? (
            <span>{record.description || record.content}</span>
          ) : (
            <TextArea
              placeholder='Section content'
              defaultValue={record.description || record.content}
              onBlur={e => handleBlur(e, record.key, 'description')}
              style={{ width: '100%' }}
              autoSize={1}
            />
          ),
          props: {
            colSpan: columns.length,
          },
        };
      }
      if (record.isSection && column.dataIndex !== 'description') {
        return {
          props: {
            colSpan: 0,
          },
        };
      }
      return isReadOnly ? (
        <span>{makeValueFixedTo2Decimals(text, column)}</span>
      ) : column.dataIndex === 'description' ? (
        <TextArea defaultValue={text} onBlur={e => handleBlur(e, record.key, column.dataIndex)} autoSize={1} />
      ) : (
        <Input
          defaultValue={makeValueFixedTo2Decimals(text, column)}
          onBlur={e => handleBlur(e, record.key, column.dataIndex)}
          className='py-0 pl-1'
        />
      );
    },
  }));

  if (showNumbering) {
    enhancedColumns.unshift({
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        if (record.isSection) {
          return '';
        }
        let count = 1;
        for (let i = 0; i < index; i++) {
          count += formik.values.documentTrainingTableData[i].isSection ? 0 : 1;
        }
        return count;
      },
    });
  }

  if (!isReadOnly) {
    enhancedColumns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 55,
      render: (_, record) => (
        <div className='flex justify-center'>
          <Button onClick={() => handleDelete(record.key)} className='shadow-none border-none'>
            <DeleteIcon />
          </Button>
        </div>
      ),
    });
  }

  return (
    <div className='document-table w-full'>
      <div className='flex flex-col items-end'>
        <Table
          sticky
          key={formik.values.id || null}
          columns={enhancedColumns}
          dataSource={formik.values.documentTrainingTableData}
          scroll={{ x: 609 }}
          pagination={false}
          className='h-[calc(100vh-260px)] overflow-y-auto border border-lightGray bg-white'
        />
        {showCounts && (
          <div className='mt-1 flex gap-2'>
            <p>{`Total Cost: ${formik.values.totalCost || 0}`}</p>
            <p>{`Total Amount: ${(parseFloat(formik.values?.totalAmount) || 0).toFixed(2)}`}</p>
          </div>
        )}
      </div>
      <ShowErrorMessage
        className='mt-2'
        hasError={formik.errors.documentTrainingTableData}
        message={formik.errors.documentTrainingTableData}
      />
      {!isReadOnly && (
        <div className='mt-5 flex gap-4'>
          <Button onClick={handleAdd} className='border-none bg-white p-0'>
            <CirclePlus color='#1565D8' className='h-4 w-4' /> Add New Row
          </Button>
          {showSectionButton && (
            <Button onClick={handleAddSection} className='border-none bg-white p-0'>
              <CirclePlus color='#1565D8' className='h-4 w-4' /> Add New Section
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
