import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowToast } from '@common/components/ShowToast';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'antd';
import GenericHelpers from '../../helpers/genericHelpers';
import ModalHelpers from '../../helpers/modalHelpers';
import { CustomModal, CustomSelect, Loader, UploadFile } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { VendorDropdownValidationSchema } from '@common/validators';
import { apiEndpoints, queryKeys, useGetQuery } from '@services';
import { ERROR } from '@constants';

const initialValues = {
  selectedVendor: null,
  file: null,
  showGeneratingAIModal: false,
};

const UploadNew = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({
    url: null,
    id: null,
    status: 'idle',
    progress: 0,
    loading: false,
  });

  const { data: vendors } = useGetQuery([queryKeys.VENDORS], apiEndpoints.vendors.LISTING);
  const formik = useFormikForm(initialValues, () => null, VendorDropdownValidationSchema);

  const modalProps = ModalHelpers.getUploadNewModalProps(formik, setIsModalOpen, navigate);
  const disableUploadBtn = formik.values.selectedVendor?.trained === false || formik.values.selectedVendor?.processing;

  useEffect(() => {
    if (formik.values.selectedVendor !== null) {
      if (!formik.values.selectedVendor?.trained) {
        setIsModalOpen(true);
      }
    }
  }, [formik.values.selectedVendor]);

  return (
    <div className='shadow-secondary flex h-full flex-col overflow-hidden rounded-xl border border-baseColor bg-white'>
      <div className='flex items-center justify-between px-6 pt-8'>
        <div>
          <h3>Upload Document</h3>
          <p className='mt-3 text-base font-normal leading-5'>Please choose or drag a file to upload</p>
        </div>
        <CustomSelect
          setSelected={vendor => formik.setFieldValue('selectedVendor', vendor)}
          options={GenericHelpers.convertToSelectOptions(vendors?.data?.results)}
          hasError={formik.errors.selectedVendor}
          message={formik.errors.selectedVendor}
        />
      </div>

      <div className='grid flex-1 grid-cols-12 gap-x-6'>
        <div className='col-span-10 col-start-2 flex items-center'>
          <UploadFile
            className='mx-[2.8125rem]'
            payloadFileKey='document'
            apiUrl={apiEndpoints.vendors.UPLOAD_FILE}
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            onSuccess={res => {
              formik.setFieldValue('file', res?.data);
              formik.setFieldValue('showGeneratingAIModal', true);
              GenericHelpers.invalidateQueries([queryKeys.NOTIFICATIONS, queryKeys.USER], queryClient);
              setIsModalOpen(true);
            }}
            extendPayload={{ vendor: formik.values.selectedVendor?.value, document_type: 'process_document' }}
            disabled={disableUploadBtn}
            beforeUpload={() => {
              if (formik.values.selectedVendor === null) {
                ShowToast(ERROR, 'Please select a vendor');
                return false;
              }
              return true;
            }}
          />
        </div>
      </div>

      <CustomModal
        open={isModalOpen}
        cancel={() => {
          setIsModalOpen(false);
          formik.setFieldValue('showGeneratingAIModal', false);
        }}
        textClass='mt-4'
        footerDangerBtnClass='hidden'
        {...modalProps}
      />

      <Modal
        centered
        open={uploadedFile?.loading}
        footer={[]}
        width={400}
        closeIcon={null}
        maskClosable={false}
        className={'screenLayoutModal'}
      >
        <div className='text-center'>
          <div className='flex items-center justify-center'>
            <Loader />
          </div>
          <h3 className='mb-3 mt-[42px] text-lg font-medium text-midnightBlue'>Uploading File</h3>
        </div>
      </Modal>
    </div>
  );
};

export default UploadNew;
