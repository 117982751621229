export default class ModalHelpers {
  static getUploadNewModalProps = (formik, setIsModalOpen) => {
    if (!formik.values.selectedVendor?.trained && !formik.values.selectedVendor?.processing) {
      return {
        heading: 'No Models Available',
        text: 'Please train a model to proceed or check your setup to ensure a model is ready to use.',
        SVG: 'AlertTriangleIcon',
      };
    } else if (formik.values.selectedVendor?.processing) {
      return {
        textPrimary: 'Close',
        heading: 'Training is underway. Try again later.',
        SVG: 'AlertTriangleIcon',
        firstBtnClick: () => setIsModalOpen(false),
      };
    } else if (formik.values.showGeneratingAIModal) {
      return {
        textPrimary: 'Ok',
        heading: 'Generating AI content',
        text: 'We’re generating your output using AI. You’ll receive a notification once it’s ready. Please check the ‘Processed Documents’ tab for the final result.',
        SVG: 'GeneratingAIIcon',
        firstBtnClick: () => {
          setIsModalOpen(false);
          formik.setFieldValue('showGeneratingAIModal', false);
        },
      };
    }
  };
}
