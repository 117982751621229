import { useEffect, useState } from 'react';
import { ReUploadFile } from '@common/components/ReUploadFile';
import { ShowErrorMessage } from '@common/components/ShowErrorMessage';
import { Button, Drawer, Input } from 'antd';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import TableHelpers from '../../helpers/tableHelpers';
import { CustomTable, PdfViewer, UploadFile } from '@common/components';
import { apiEndpoints } from '@services';
import { DividerIcon } from '@assets/svgs';

export const DocumentDrawer = ({
  title,
  btnText,
  open,
  onClose,
  formik,
  optionalBtnText,
  optionalBtnHandler = () => null,
  onSearchKeyDown = () => null,
  isReadOnly = false,
  hasModelTrainingTable = false,
  showReUploadButton = true,
  showCancelBtn = true,
  disable = false,
  className = '',
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [uploadedFile, setUploadedFile] = useState({
    url: null,
    id: null,
    status: 'idle',
    progress: 0,
  });

  useEffect(() => {
    setInputValue(formik.values.summary || formik.values.markupValue);
  }, [formik.values.summary, formik.values.markupValue]);

  return (
    <Drawer
      title={title}
      width='100%'
      onClose={onClose}
      open={open}
      className='bg-red-500'
      extra={
        btnText && (
          <div className='flex gap-2'>
            {showCancelBtn && (
              <Button disabled={disable} onClick={onClose} className='border-none'>
                Cancel
              </Button>
            )}
            {optionalBtnText && (
              <Button disabled={disable} onClick={optionalBtnHandler}>
                {optionalBtnText}
              </Button>
            )}
            <Button className={className} disabled={disable} type='primary' onClick={formik.handleSubmit}>
              {btnText}
            </Button>
          </div>
        )
      }
    >
      <PanelGroup direction='horizontal' className='items-center gap-2'>
        <Panel
          defaultSize={30}
          minSize={20}
          className='flex h-full w-full flex-col rounded-3xl border border-silverBlue bg-white px-6 py-[1.125rem]'
        >
          <div className='mb-4 flex items-center justify-between'>
            <h3>Input File</h3>
            {showReUploadButton && <ReUploadFile formik={formik} />}
          </div>
          <div className='flex w-[86%] flex-1 items-center'>
            {formik.values.file === null ? (
              <div className='w-full flex-row'>
                <UploadFile
                  className={`mx-[2.8125rem] ${formik.errors.file && 'border-warning'}`}
                  payloadFileKey='document'
                  apiUrl={apiEndpoints.vendors.UPLOAD_FILE}
                  setUploadedFile={setUploadedFile}
                  uploadedFile={uploadedFile}
                  onSuccess={res => formik.setFieldValue('file', res?.data)}
                  extendPayload={{ vendor: formik.values.selectedVendor?.value, document_type: 'training_document' }}
                />
                <ShowErrorMessage
                  className='mx-[2.8125rem] mt-2'
                  hasError={formik.errors.file}
                  message={formik.errors.file}
                />
              </div>
            ) : (
              <PdfViewer url={formik.values.file?.document} />
            )}
          </div>
        </Panel>
        <div className='relative'>
          <DividerIcon />
          <PanelResizeHandle className='absolute top-0 z-10 h-10 w-10' />
        </div>
        <Panel
          defaultSize={60}
          minSize={20}
          className='h-full w-full rounded-3xl border border-silverBlue bg-white px-6 py-[1.125rem]'
        >
          <div className='mb-2.5 flex w-full items-center justify-between'>
            <h3 className='font-[Inter] text-[1.125rem] font-medium leading-[1.375rem] text-midnightBlue'>
              Output File
            </h3>
            {!hasModelTrainingTable ? (
              isReadOnly ? (
                <p>{`Markup Value: ${formik.values.markupValue || 0}`}</p>
              ) : (
                <Input
                  type='number'
                  name='markupValue'
                  placeholder='Enter Markup'
                  className='h-[2rem] w-full max-w-[14.125rem] rounded-md border border-silverBlue !px-3 py-1 text-sm font-medium leading-[1.125rem] focus:outline-none'
                  disabled={disable}
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  onBlur={formik.handleChange}
                  onKeyDown={onSearchKeyDown}
                />
              )
            ) : (
              <Input
                name='summary'
                placeholder='Summary'
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onBlur={formik.handleChange}
                className='h-[2rem] w-full max-w-[14.125rem] rounded-md border border-silverBlue !px-3 py-1 text-sm font-medium leading-[1.125rem] focus:outline-none'
              />
            )}
          </div>
          <CustomTable
            formik={formik}
            isReadOnly={isReadOnly}
            showCounts={!hasModelTrainingTable}
            showSectionButton={!hasModelTrainingTable}
            columns={hasModelTrainingTable ? TableHelpers.modelColumns : TableHelpers.documentColumns}
          />
        </Panel>
      </PanelGroup>
    </Drawer>
  );
};
